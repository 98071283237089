/* ### DEFAULT VALUES - XS ### */

.layout-header-nav .nav-item a.btn:hover,
.layout-header-nav .nav-item a.btn:focus-visible {
    text-decoration: none;
}

.layout-header-nav .navbar-toggler {
    min-width: 45px;
    min-height: 40px;
    --bs-navbar-toggler-padding-x: 0.25rem;
    --bs-navbar-toggler-focus-width: 0.1rem;
}

.layout-header-nav .navbar-toggler .navbar-close {
    font-size: 1.75rem;
}

.layout-header-nav .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon,
.layout-header-nav .navbar-toggler[aria-expanded="false"] .navbar-close {
    display: none;
}

.layout-header-nav .navbar-toggler[aria-expanded="false"] .navbar-toggler-icon,
.layout-header-nav .navbar-toggler[aria-expanded="true"] .navbar-close {
    display: inline-block;
}

/* NOTE: This may not work with the new layout-header-nav as the navbar itself is in a different parent div */
.layout-header-nav .navbar-toggler[aria-expanded="false"] ~ .navbar.collapsing {
    display: none;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {

}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
    
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
