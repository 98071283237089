/* ### DEFAULT VALUES - XS ### */

.icon-tick:before {
    content: "\2714\fe0e";
    font-size: 1.1rem;
}

.tick-start:before {
    content: "\2714\fe0e";
    color: #3ea736;
    padding-right: 5px;
}

.cross-start:before {
    content: "\2718\fe0e";
    color: red;
    padding-right: 5px;
}

.rounded-icon {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 1rem;
}

.rounded-icon ~ span {
    display: block;
    vertical-align: middle;
}

.rounded-icon span {
    border: solid 1px white;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    text-align: center;
    line-height: 85px;
    height: 85px;
    width: 85px;
    display: inline-block;
    font-size: 3rem;
}
.rounded-icon .icon-hands:before {
    font-size: 2.5rem;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
    .rounded-icon {
        margin-bottom: 0;
    }

    .rounded-icon ~ span {
        display: inline-block;
    }
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {

}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
