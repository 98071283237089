/* ### DEFAULT VALUES - XS ### */

.customer-support .customer-support-icon {
    background-color: #39a143;
    color: white;
    font-size: 2.4rem;
    line-height: 66px;
    height: 70px;
    width: 70px;
}

.cs-panel .item-header {
    font-size: 1rem;
    font-weight: 600;
    color: #5d257c;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {

}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
