/* ### DEFAULT VALUES - XS ### */

.panel {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-radius: 5px;
    border: 0;
}
.panel.has-footer {
    padding-bottom: 0;
}

.panel > .card-header {
    font-weight: 600;    
    border-bottom: none;
    background-color: transparent;
    font-size: 1.5rem;
    padding-top: 1rem;
}

.panel > .card-footer {
    border-top: none;
    background-color: transparent;
    padding-bottom: 1rem;
}

.panel-section {
    margin-bottom: 1rem;
}
.panel-section .section-heading {
    font-weight: 700;
    margin-bottom: 1rem;
}
.panel-section .section-heading .underline {
    border-bottom: 2px solid #ccc;
    padding-bottom: 0.5rem;
    font-weight: 700;
}
.panel-section.panel-header .section-heading {
    font-weight: 600;
    font-size: 1.5rem;
}
.panel-section.panel-header .section-heading .underline {
    border-bottom: none;
    padding-bottom: 0;
    font-weight: 600;
}

.panel-section .section-children > div:not(.display-item) {
    margin-bottom: 0.35rem;
}

.panel-section .section-children.div-gap-3 > div {
    margin-bottom: 1rem;
}

.panel.panel-bg > .card-header {
    font-size: 1.25rem;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {

    .panel > .card-header {
        padding: 1rem 2rem 0 2rem;
    }
    
    .panel > .card-body {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .panel > .card-body:first-child {
        padding-top: 2rem;
    }
    .panel > .card-body:last-child {
        padding-bottom: 0;
    }
    
    .panel-section:not(:last-child) {
        margin-bottom: 1.5rem;
    }
    .panel-section:last-child {
        margin-bottom: 1rem;
    }    
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
