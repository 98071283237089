/* ### DEFAULT VALUES - XS ### */

.panel {
    
}

.panel.has-border {
    border: 1px solid #C4E4C2;
}
.panel.has-thick-border {
    border: 2px solid #3EA736;
}

.panel > .card-header { 
    color: #5d257c;
}

.panel > .card-header .main-header {
    color: black;
    font-size: 2rem;
    padding-bottom: 0.5rem;
}
.panel > .card-header .main-header .left-content {
    border-bottom: 4px solid #3EA736;
}
.panel > .card-header .main-header .icon {
    color: #656565;
    font-size: 2.5rem;
}
.panel > .card-header .right-content img {
    max-height: 60px;
}

.panel-section .section-heading .underline {
    border-bottom-color: #3EA736;
}
.panel-section.panel-header .section-heading {
    color: #5d257c;
}

.panel.panel-bg {
    background-color: #EDF2EC;
}
.panel.panel-bg > .card-header {
    color: black;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {
    .panel > .card-body {
        
    }
    .panel > .card-header.main-header .icon {
        font-size: 3.5rem;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
