/* ### DEFAULT VALUES - XS ### */

.contact-us .contact-us-icon {
    background-color: #39a143;
    color: white;
    font-size: 2.4rem;
    line-height: 66px;
    height: 70px;
    width: 70px;
}

.contact-us .info > div:not(:last-child) {
    margin-bottom: 0.5rem;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {

}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
