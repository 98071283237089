/* ### DEFAULT VALUES - XS ### */

.opex-card {
    min-height: 250px;
}

.opex-card .card-img {
    width: auto;
    max-height: 150px;
    margin: auto auto;
    opacity: 0.05;
}

.opex-card .card-header {
    font-weight: 600;
}

.opex-layout-1 .opex-card .card-header {
    font-size: 1rem;
}
.opex-layout-2 .opex-card .card-header {
    font-size: 1rem;
}

.bd-table.table > :not(caption) > * > * {
    padding: 0.75rem 0.5rem;
}

.breakdown-table {
    margin-left: 0px;
    margin-right: 0px;
    font-size: 0.8rem;
    margin-bottom: 1rem;
}

.breakdown-table .title {
    font-weight: bold;
    font-size: 0.75rem;
    padding: 0.5rem 0;
}

.breakdown-table .bi {
    font-size: 1.15rem;
    color: #0d6efd;
}

.breakdown-table .bi {
    font-size: 1.15rem;
}

.breakdown-table .benefit-text {
    font-size: 0.75rem;
    padding: 0.5rem 0;
}

.breakdown-table .benefit-text span[type=button] {
    -webkit-appearance: initial;
    overflow-wrap: initial;
}

.breakdown-table .benefit-info {
    font-size: 0.75rem;
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    padding-right: calc(var(--bs-gutter-x) * 0.5);
}

.breakdown-table .benefit-info span {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.breakdown-table .shaded {
    background-color: #F8F8F8;
    border-top: 1px solid #EDEDED;
    border-bottom: 1px solid #EDEDED;
}

.breakdown-table .price {
    font-size: 1rem;
    display: inline-block;
    padding: 1rem 0.1rem 0.5rem 0.1rem;
    font-weight: bold;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
    .breakdown-table .benefit-text {
        font-size: 0.875rem;
    }
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {
    .opex-card .card-img {
        max-height: 150px;
    }

    .opex-layout-1 .opex-card .card-header {
        font-size: 1.25rem;
    }
    .opex-layout-2 .opex-card .card-header {
        font-size: 1.25rem;
    }

    .breakdown-table {
        font-size: 1rem;
    }

    .breakdown-table .title {
        font-size: 0.9rem;
    }

    .breakdown-table .benefit-text {
        font-size: 0.875rem;
        padding-left: 0.5rem;
    }

    .breakdown-table .benefit-info {
        padding-left: 0.5rem;
    }

    .breakdown-table .price {
        font-size: 1.25rem;
        display: inline-block;
        padding: 1rem 1rem 0.5rem 1rem;
    }

    .breakdown-table .no-thanks {
        padding: 0.5rem 1rem;
    }

    .breakdown-table .price {
        font-size: 1.25rem;
        display: inline-block;
        padding: 1rem 1rem 0.5rem 1rem;
    }

    .breakdown-table .no-thanks {
        padding: 0.5rem 1rem;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
    .opex-layout-2 .opex-card .card-header {
        font-size: 1rem;
    }
    .opex-layout-2 .opex-card .card-footer .tel-no-link {
        display: block;
    }
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
