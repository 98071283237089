/* ### DEFAULT VALUES - XS ### */

.layout-header {
    background-color: white;
    min-height: 110px;
}

.layout-header .brand-img {
    max-height: 50px;
}

.header-images.hi-colour {
    height: 350px;
    margin-top: 0;
}

.layout-header .contact-details .tel-link {
    color: #3ea736;
    margin-left: 0.25rem;
}

.layout-header-nav {
    background-color: rgba(255,255,255,0.8);
    backdrop-filter: blur(5px) !important;
}
.layout-header-nav .nav-main {
   min-height: 90px;
}

.layout-header-nav .navbar-toggler {
    background-color: #fff;
    --bs-navbar-toggler-border-color: #3EA736;
}

.layout-header-nav .nav-links {
    background-color: white;
    color: black;
    opacity: 1;
}
.layout-header-nav .nav-links .nav-link {
    color: black;
}

.layout-header-nav .nav-links .navbar {
    --bs-navbar-padding-y: 0;
}

.layout-header-nav .mobile-text {
    min-height: 40px;
    background-color: #5d257c;
    opacity: 1;
    color: white;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {

    .layout-header .brand-img {
        max-height: 60px;
    }

    .layout-header .nav-left,
    .layout-header .nav-center,
    .layout-header .nav-right {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .layout-header-nav {
        min-height: 110px;
    }

    .header-images.hi-colour {
        height: 424px;
        margin-top: 0;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
    .layout-header-nav .nav-links {
        background-color: #5d257c;
        color: white;
    }
    .layout-header-nav .nav-links .nav-link {
        color: white;
    }
    .layout-header-nav .nav-links .nav-link:focus-visible {
        box-shadow: none;
    }
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}