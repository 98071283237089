/* ### DEFAULT VALUES - XS ### */

.page-header {
    padding: 3rem 0;
}

.page-header .rounded-icon span {
    border: 0;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {
    .page-header {
        padding: 2rem 1rem 3rem 1rem;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
