/* ### DEFAULT VALUES - XS ### */

.landing .layout-main {
    padding-top: 0;
    padding-bottom: 0;
}

.landing .white-full-width {
    background-color: white;
}

.landing .panel-bg .product-logo {
    max-height: 50px;
}
.landing .panel-bg .product-text {
    font-size: 0.939rem;
    font-weight: 400;
}

.landing .panel-bg .panel-section .section-heading {
    font-weight: 600;
    margin-bottom: 0.25rem;
}
.landing .panel-bg .panel-section .section-heading .underline {
    border-bottom: none;
    font-weight: 600;
    padding-bottom: 0;
}

.landing .trust-panel {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {
    .landing .panel-bg .panel-section:last-child {
        margin-bottom: 0;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
    .landing .trust-panel {
        padding-bottom: 0;
    }
    .landing .trust-panel .trustpilot-widget {
        height: 200px;
    }
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
