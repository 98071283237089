/* ### DEFAULT VALUES - XS ### */

.list-style-flex .list-item .bullet {
    color: #3EA736;
}

.accordion {
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.25);
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {

}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
