/* ### DEFAULT VALUES - XS ### */

.layout-container {
    background-color: #EDF2EC;
}

.layout-max-width {
    max-width: 950px;
}
.layout-wide .layout-max-width {
    max-width: 1200px;
}

.layout-main {
    padding-top: 2rem;
    padding-bottom: 5rem;
}
.layout-wide .layout-main > .container-fluid {
    padding-left: 0;
    padding-right: 0;
}

/* Add a top margin where there is no progress bar */
.layout-main > .row:first-child:not(.site-progress) {
    margin-top: 3rem;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {
    
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
    
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}