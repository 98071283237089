/* ### DEFAULT VALUES - XS ### */

a {
    text-decoration: none;
    font-weight: 400;
}
a:hover {
    text-decoration: underline;
}
a:focus-visible {
    text-decoration: underline;
    outline: none;
}

.text-danger .tel-link {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity));
}

.flex-break {
    flex-basis: 100%;
    height: 0;
}

.list-style-flex {
    padding-left: 1rem;
}
.list-style-flex .list-item:not(:last-child) {
    margin-bottom: 0.75rem;
}
.list-style-flex .list-item .bullet {
    font-size: 0.5rem;
    margin-top: 0.3rem;
    margin-right: 0.5rem;
}

.app-links {
    
}
.app-links .app-badge {
    height: 50px;
    display: inline-block;
}
.app-links .app-badge img {
    height: 100%;
}
.appstore-badge {}
.google-badge{}


/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {

}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
