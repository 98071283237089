/* ### DEFAULT VALUES - XS ### */

.telematics-card {
    border: none;
}
.telematics-card > .card-header {
    background-color: transparent;
    border-bottom: none;
    padding: 0.5rem 0;
}
.telematics-card > .card-body {
    padding: 0 0 1rem 0;
}
.telematics-card .description {
    
}
.telematics-card .img-container {
    padding: 2rem 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.telematics-card .img-container img {
    max-height: 240px;
    display: block;
    margin: auto auto;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {
    .telematics-card .img-container img {
        max-height: 170px;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
