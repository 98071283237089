/* ### DEFAULT VALUES - XS ### */

.term-1 {
    margin-bottom: 1rem;
}
.term-1 .term-number {
    margin-left: 1rem;
}

.term-2 {
    margin-bottom: 1rem;
}
.term-2 .term-number {
    margin-left: 2rem;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {

}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
