/* ### DEFAULT VALUES - XS ### */

.header-images {
    height: 585px;
    position: relative;
    overflow: hidden;
    margin-top: -130px;
}
.header-images .slideshow-carousel {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    z-index: -2;
}
.header-images .carousel-inner {
    height: 100%;
}

.header-images .carousel-item {
    width: 100%;
    height: 100%;
    background-position: 50%;
    background-size: cover;
}
.header-images .carousel-caption {
    text-align: left;
    position: static;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {
    .header-images {
        height: 685px;
    }
    .header-images .carousel-caption {
        position: absolute;
    }
    .header-images .strap-line {
        font-size: 2rem;
        font-weight: 400;
        margin-bottom: 1rem;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
